<!-- Navbar -->
<app-navbar-style-one></app-navbar-style-one>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Privacy Policy</h2>
                    <ul>
                        <li>
                            <img
                                src="assets/img/home-three/title-img-two.png"
                                alt="Image"
                            />
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Privacy Policy</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Rules -->
<div class="rules-area ptb-100">
    <div class="container">
        <div style="width: 680px" class="fleft">
            <style>
                .boldtxt {
                    font-weight: bold;
                }
                .paddt15 {
                    padding-top: 15px;
                }
            </style>
            <div class="bdr1 paddl15 paddr15 paddt10 paddb10">
                <div class="hdtxt1 boldtxt paddb10">
                    Privacy Policy of TnYadavs Matrimony
                </div>
                <div class="payhbdr"><!-- --></div>
                <div class="paddt15 paddb5 paddl5 paddr5 lheight18 txt-justify">
                    <p>
                        tnyadavs.com is an online matrimonial portal
                        endeavouring constantly to provide you with matrimonial
                        services. This privacy statement is common to all the
                        matrimonial Website/apps operated under tnyadavs.com
                        Since we are strongly committed to your right to
                        privacy, we have drawn out a privacy statement with
                        regard to the information we collect from you. You
                        acknowledge that you are disclosing information
                        voluntarily. By accessing /using the website/apps and/or
                        by providing your information, you consent to the
                        collection, use, share, store and process the info you
                        disclose on the website/apps in accordance with this
                        Privacy Policy. If you do not agree for use of your
                        information, please do not use or access this
                        website/apps.
                    </p>

                    <p class="boldtxt paddt15">
                        What information you need to give in to use this
                        Website/apps?
                    </p>

                    <p class="paddt15">
                        The information we gather from members and visitors who
                        apply for the various services our website/apps offers
                        includes, but may not be limited to, email address,
                        name, date of birth, identification proofs / address
                        proofs, educational qualifications a user-specified
                        password, mailing address, zip/pin code and
                        telephone/mobile number or fax number.
                    </p>

                    <p class="paddt15">
                        We use a secure server for credit card transactions to
                        protect the credit card information of our users and
                        Cookies are used to store the login information. Cookies
                        are small files placed on your hard drive that will
                        assist us in providing our services. You may also
                        encounter Cookies or identical/related devices on
                        certain pages of the website/apps that are placed by
                        third parties. We do not control the use of cookies by
                        third parties.
                    </p>

                    <p class="paddt15">
                        If you establish a credit account with us to pay the
                        fees we charge, some additional information, including a
                        billing address, a credit/debit card number and a
                        credit/debit card expiration date and tracking
                        information from cheques or demand drafts is collected.
                    </p>

                    <p class="paddt15">
                        The user information we collect depends on the context
                        of your interactions with us and the website or Apps,
                        the choices you make and the products and features you
                        use. The User Information is used for authentication and
                        account access, If a user registers using social
                        networking platforms such as Facebook, Google, LinkedIn
                        and others we may collect personal data you choose to
                        allow us to access through their APIs. When the user
                        accesses our websites or apps, data relating to device
                        ID, log files ,Geographic Location, device
                        Information/specification are also collected
                        automatically.
                    </p>

                    <p class="paddt15">
                        We may use also your personal information for
                        verification, analysis of data, usage trends and to
                        evaluate and improve our site/App, marketing research ,
                        preventing of frauds. In our efforts to continually
                        improve our product and service offerings, we collect
                        and analyse demographic and profile data about our
                        users' activity on our website/apps. We identify and use
                        your IP address to help diagnose problems with our
                        server, and to administer our website/apps. Your IP
                        address is also used to help identify you and to gather
                        broad demographic information.
                    </p>

                    <p class="paddt15 boldtxt">
                        How the website/apps uses the information it
                        collects/tracks?
                    </p>

                    <p class="paddt15">
                        tnyadavs.com collects information for data analysis,
                        identifying usage trends, determining the effectiveness
                        of our promotional campaigns and to evaluate and improve
                        our websites or apps, products, and services ,marketing
                        research from our users primarily to ensure that we are
                        able to fulfil your requirements and to deliver
                        Personalised experience.
                    </p>

                    <p class="paddt15 boldtxt">
                        For European Union Members (EU)
                    </p>

                    <p class="paddt15">
                        If you are located in the EU, you will be asked to
                        provide consent to the collection, processing, and
                        sharing of your personal information. Personal
                        information means any information related to an
                        identified or identifiable natural person. You have the
                        right to share and access your personal information and
                        right to withdraw consent for sharing your personal
                        information at any point of time and right to erase your
                        personal information subject to applicable laws. for
                        sharing your personal information at any point of time.
                        You can withdraw your consent provided by contacting us.
                        Your personal information may be stored in databases
                        located outside of the EU including in India. Where we
                        transfer personal data outside of EU, we either transfer
                        personal information to countries that provide an
                        adequate level of protection or we have appropriate
                        safeguards in place.&nbsp;We may require proof of or
                        need to verify your identity before we can give effect
                        to these rights. To request to review, update, or delete
                        your personal information, please submit a request form
                        by sending an email to
                        <a
                            href="mailto:privacy@tnyadavs.com"
                            class="link"
                            style="text-decoration: none"
                            >privacy@tnyadavs.com</a
                        >. We may share your information with third parties who
                        are an anti-fraud solution provider(s) located in UK.
                        They help us to ensure we keep you safe from scammers
                        and fraudster.
                    </p>

                    <p class="paddt15 boldtxt">
                        With whom the website/apps shares the information it
                        collects/tracks?
                    </p>

                    <p class="paddt15">
                        We may share such identifiable information with our
                        associates/affiliates/subsidiaries and such
                        associates/affiliates/subsidiaries may market to you as
                        a result of such sharing. Any information you give us is
                        held with the utmost care and security. We are also
                        bound to cooperate fully should a situation arise where
                        we are required by law or legal process to provide
                        information about a customer/visitor.
                    </p>

                    <p class="paddt15">
                        Where required or permitted by law, information may be
                        provided to others, such as regulators and law
                        enforcement agencies or to protect the rights, property
                        or personal safety of other members or the general
                        public. We may voluntarily share your information with
                        law enforcement agencies / Gateway service providers /
                        anti-fraud solution provider(s) if we feel that the
                        transaction is of suspicious nature.
                    </p>

                    <p class="paddt15">
                        From time to time, we may consider corporate
                        transactions such as a merger, acquisition,
                        reorganization, asset sale, or similar. In these
                        instances, we may transfer or allow access to
                        information to enable the assessment and undertaking of
                        that transaction. If we buy or sell any business or
                        assets, personal information may be transferred to third
                        parties involved in the transaction.
                    </p>

                    <p class="paddt15">
                        Our website/apps links to other website/apps that may
                        collect personally identifiable information about you.
                        We are not responsible for the privacy policy or the
                        contents of those linked website/apps.
                    </p>

                    <p class="paddt15 boldtxt">
                        How Long Do We Keep Your Information?
                    </p>

                    <p class="paddt15">
                        As stipulated in the Privacy Policy we will retain the
                        information we collect from users under the following
                        circumstances:
                    </p>

                    <p class="paddt15">
                        For as long as the users subscribe to our services to
                        meet their suitable purpose(s) for which it was
                        collected, for the sake of enforcing agreements, for
                        performing audits, for resolving any form of disputes,
                        for establishing legal defences, for pursuing legitimate
                        businesses and to comply with the relevant applicable
                        laws.
                    </p>

                    <p class="paddt15 boldtxt">
                        What are the Security Precautions in respect of your
                        personal information?
                    </p>

                    <p class="paddt15">
                        We aim to protect your personal information through a
                        system of organizational and technical security
                        measures. We have implemented appropriate internal
                        control measures designed to protect the security of any
                        personal information we process. However, please also
                        remember that we cannot guarantee that the internet
                        itself is 100% secure. Once your information is in our
                        possession, we adhere to security guidelines protecting
                        it against unauthorised access.
                    </p>

                    <p class="paddt15 boldtxt">Change of Privacy Policy</p>

                    <p class="paddt15">
                        We may change this Privacy Policy from time to time
                        without any notice to you. However, changes will be
                        updated in the Privacy Policy page.
                    </p>

                    <p class="paddt15">
                        <span class="boldtxt"
                            >How to address your Grievance :</span
                        >
                        The Grievance officer : Mr. Khader Mohiadeen. Address;
                        Matrimony.com Limited, Contact details: No 4,
                        Thirumazhisai Via, Pudhuchatram Post, Poonamallee Taluk.
                        Chennai - 600124
                        <a
                            href="mailto:info@matrimony.com"
                            class="link"
                            style="text-decoration: none"
                            >info@tnyadavscom</a
                        >
                    </p>

                    <p class="paddt15">
                        The Grievance officer shall be available between 10 am
                        to 6 pm IST from Monday to Saturday excluding Sunday's
                        and Public Holidays in India.
                    </p>

                    <p class="paddt15">
                        The Grievance officer is appointed as per Section 5 (9)
                        of the Information Technology ( Reasonable Security
                        &amp; Procedures and Sensitive Personal data or
                        Information ) Rule, 2011.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Rules -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
